<template>
  <div>
    <div class="text-right q-pb-sm">

      <q-btn
        v-if="tab === 'prices'"
        color="dark"
        text-color="white"
        size="sm"
        class="q-mr-sm"
        :label="$t('Status')"
        no-caps
        @click="isExpandedStatus = !isExpandedStatus"
      />

      <q-btn
        color="dark"
        text-color="white"
        size="sm"
        class="q-mr-sm"
        :label="$t('Prices')"
        :disable="tab === 'prices'"
        no-caps
        @click="handleTabChange('prices')"
      />

      <q-btn
        color="dark"
        text-color="white"
        size="sm"
        :disable="tab === 'servicePoints'"
        :label="$t('Service Points')"
        no-caps
        @click="handleTabChange('servicePoints')"
      />
    </div>

    <div class="row">
      <div class="col-md-12">
        <div v-if="tab === 'servicePoints'" class="q-px-md">
          <q-input
            :model-value="search"
            type="text"
            color="white"
            standout="bg-teal text-white"
            :label="$t('Search')"
            :debounce="200"
            class="text-white q-pb-sm"
            label-color="light"
            @update:model-value="handleSearch"
          />

          <q-scroll-area style="height: 300px;">
            <q-list
              bordered
              separator
            >
              <q-item
                v-for="service of filteredServicePoints"
                :key="service.id"
                v-ripple
                clickable
                :disable="disabled"
                @click="handleMarkerSelect(service)"
              >
                <q-item-section
                  thumbnail
                  class="q-px-sm"
                >
                  <img
                    :src="checkLogo(service)"
                    style="width: 50px; height: 50px; object-fit: contain;"
                  >
                </q-item-section>

                <q-item-section>
                  <h5 class="q-my-none text-subtitle1">
                    {{ `${service.name} (${service.id}) ` }}
                  </h5>

                  <p class="text-caption q-my-none">
                    {{ service.address }}
                  </p>

                  <small>{{ service.timetable }}</small>
                </q-item-section>

                <q-item-section
                  style="max-width: 80px;"
                  class="q-mr-sm"
                >
                  <q-btn
                    color="light-blue-9"
                    text-color="white"
                    size="sm"
                    :label="$t('Choose')"
                    no-caps
                    unelevated
                    :disable="disabled"
                    @click="handleServicePoint(service)"
                  />
                </q-item-section>
              </q-item>
            </q-list>
          </q-scroll-area>
        </div>

        <div
          v-if="tab === 'prices'"
          class="q-pr-sm"
        >
          <q-slide-transition>
            <div
              class="q-pa-sm block text-center q-item-no-border full-width"
            >
                <form @submit.prevent="handleSubmitDimmensions">
                <q-card class="col no-border" flat borderless>

                  <q-card-section class="row no-wrap">
                    <div class="col-3">
                        <q-btn-group class="q-px-xs">
                            <editable-input-field
                                    label="Weight"
                                    :value="userWeight || weight"
                                    :input-label="`Weight (${appOptions.defaultDimensions.weightUnit})`"
                                    :update-function="newValue => handleNumberUpdate('weight' , newValue)"
                                    @change-catch-all="enableCatchAll"
                            ></editable-input-field>
                        </q-btn-group>
                    </div>

                    <div class="col-1">

                    </div>

                    <div class="col-8">
                        <q-btn-group class="q-px-xs">
                            <editable-input-field
                                    label="W"
                                    :value="userWidth || width"
                                    :input-label="`W (${appOptions.defaultDimensions.dimensionUnit})`"
                                    :update-function="newValue =>  handleNumberUpdate('width' , newValue)"
                                    @change-catch-all="enableCatchAll"
                            ></editable-input-field>

                            <editable-input-field
                                    label="H"
                                    :value="userHeight || height"
                                    :input-label="`H (${appOptions.defaultDimensions.dimensionUnit})`"
                                    :update-function="newValue => handleNumberUpdate('height' , newValue)"
                                    @change-catch-all="enableCatchAll"
                            ></editable-input-field>

                            <editable-input-field
                                    label="L"
                                    :value="userLength || length"
                                    :input-label="`L (${appOptions.defaultDimensions.dimensionUnit})`"
                                    :update-function="newValue => handleNumberUpdate('length' , newValue)"
                                    @change-catch-all="enableCatchAll"
                            ></editable-input-field>
                        </q-btn-group>
                    </div>
                  </q-card-section>

                <div class="text-center">
                    <q-btn
                            color="dark"
                            text-color="white"
                            class="q-mb-sm"
                            :label="$t('Calculate')"
                            no-caps
                            size="sm"
                            :disable="isWaitOn"
                            type="submit"
                    />

                    <q-btn
                            color="dark"
                            text-color="white"
                            class="q-mb-sm q-ml-sm"
                            size="sm"
                            :label="$t('Save')"
                            :disable="!isSaveActive"
                            no-caps
                            @click="handleSave"
                    />
                </div>

                <q-card-section v-if="isWaitOn">
                    <div class="q-py-sm text-subtitle1">
                        <strong>
                            {{'Please wait. We are loading the rates.'}}
                        </strong>
                    </div>
                </q-card-section>

             </q-card>
              </form>
            </div>
          </q-slide-transition>


          <q-slide-transition>
              <div
                v-if="isExpandedStatus && deliveryServices"
                class="q-pa-sm"
              >
                <q-table
                  row-key="id"
                  class="full-width table--only-header"
                  :title="$t('Status')"
                  :rows-per-page-label="$t('Rows per page')"
                  :rows="deliveryServicesValues"
                  :columns="columnsStatus"
                  :pagination="{ totalRowsNumber: deliveryServices.length }"
                  virtual-scroll
                  bordered
                  flat
                >

                  <template v-slot:body="props">
                    <q-tr :props="props">
                      <q-td
                        key="deliveryServiceLogo"
                        :props="props"
                      >
                        <img
                          v-if="props.row.logo"
                          :src="checkLogoServices(props.row)"
                          :title="props.row.name"
                          style="width: 50px; height: 50px; object-fit: contain;"
                        >

                        <h6
                          v-if="!props.row.logo"
                          class="q-my-none"
                        >
                            {{ props.row.name }}
                        </h6>
                      </q-td>
                      <q-td
                        key="status"
                        :props="props"
                        class="text-wrap"
                      >
                        <div v-if="props.row.errors" class="text-wrap" size="sm" style="white-space: pre-wrap">
                                {{props.row.errors}}
                        </div>
                        <div v-else>
                            {{'--'}}
                        </div>
                      </q-td>
                    </q-tr>
                  </template>
                </q-table>

              </div>
          </q-slide-transition>

          <q-list
            bordered
            separator
            class="rounded"
          >
            <q-item
              v-for="row in results"
              :key="row.title"
              class="q-pa-none"
            >
              <q-table
                row-key="id"
                class="full-width table--only-header"
                :title="$t(row.title || '')"
                :rows-per-page-label="$t('Rows per page')"
                :rows="row.items"
                :columns="columns"
                :pagination="{ page: 1, rowsPerPage: 250, totalRowsNumber: row.items.length }"
                virtual-scroll
                flat
                :sort-method="customSortMethod"
              >
                <template v-slot:body="props">
                  <q-tr :props="props">
                    <q-td
                      key="before"
                      :props="props"
                    >
                      <input
                        :id="'rate_id_' + props.row.id"
                        type="radio"
                        name="rate"
                        :disable="disabled"
                        :model-value="props.row.id"
                        @click="handleRateClick(props.row)"
                      >

                      <label :for="'rate_id_' + props.row.id" />
                    </q-td>

                    <q-td
                      key="deliveryServiceLogo"
                      :props="props"
                    >
                      <img
                        v-if="deliveryServices[props.row.deliveryService.id].logo"
                        :src="checkLogo(props.row)"
                        :title="props.row.deliveryService.name"
                        style="width: 50px; height: 50px; object-fit: contain;"
                      >

                      <h5
                        v-if="!deliveryServices[props.row.deliveryService.id].logo"
                        class="q-my-none"
                      >
                        {{ props.row.deliveryService.name }}
                      </h5>
                    </q-td>

                    <q-td
                      key="shipping"
                      :props="props"
                      class="text-wrap"
                      style="white-space: pre-wrap; "
                    >
                      <h5 class="q-my-none text-caption">
                        {{ `${props.row.name} (${props.row.id})` }}
                      </h5>

                    </q-td>

                    <q-td
                      key="deliveryTime"
                      :props="props"
                    >
                      <div v-if="props.row.deliveryTime">
                          {{ $moment(props.row.deliveryTime.min).format(appOptions.formats.fullDate) }}
                        <span v-if="props.row.deliveryTime.max && props.row.deliveryTime.min !== props.row.deliveryTime.max">
                          &mdash; {{ $moment(props.row.deliveryTime.max).format(appOptions.formats.fullDate) }}
                        </span>
                      </div>
                    </q-td>

                    <q-td
                      key="price"
                      :props="props"
                    >
                      <strong>
                        {{ Number(props.row.price).toFixed(2) }}

                        <span v-if="! getCurrencyIcon(props.row.currency) && props.row.currency">
                          {{ props.row.currency.code }}
                        </span>
                      </strong>

                      <i
                        v-if="getCurrencyIcon(props.row.currency)"
                        class="fas"
                        :class="getCurrencyIcon(props.row.currency)"
                      />
                    </q-td>
                  </q-tr>
                  <q-tr v-if="props.row.errors" class="bg-danger">
                      <q-td
                        key="before"
                        colspan="5"
                        :props="props"
                      >
                          <strong>
                              {{props.row.errors}}
                          </strong>
                      </q-td>
                  </q-tr>
                </template>
              </q-table>
            </q-item>
          </q-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Services
import { HALApiService } from '../../services'

// Configs
import PinsConfig from '../../config/PinsConfig'

import { LMap, LTileLayer, LMarker, LPopup, LIcon, LLayerGroup } from '@vue-leaflet/vue-leaflet/src/lib'
import EditableInputField from "@/apps/app/components/delivery-services/EditableInputField.vue";

export default {
  emits: ['sender-change', 'locality-change', 'calculation', 'on-service-point-change', 'rate' , 'update-dimensions'],
  components: {
    EditableInputField,
    'l-map': LMap,
    'l-tile-layer': LTileLayer,
    'l-marker': LMarker,
    'l-popup': LPopup,
    'l-icon': LIcon,
    'l-layer-group': LLayerGroup
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    debug: {
      type: Boolean,
      default: false
    },
    clearCache: {
      type: Boolean,
      default: false
    },
    deliveryRequest: Object,
    weight: Number,
    width: Number,
    height: Number,
    length: Number,
    divisor: {
      type: Number,
      default: 5000
    }
  },
  data () {
    return {
      addressModel: {
        sender: this.sender,
        locality: this.locality
      },
      isSaveActive: false,
      pinTypes: PinsConfig,
      calculation: {},
      columnsStatus: [
        {
          name: 'deliveryServiceLogo',
          align: 'left'
        },
        {
          name: 'status',
          align: 'left'
        }
      ],
      columns: [
        {
          name: 'before',
          align: 'left'
        },
        {
          name: 'deliveryServiceLogo',
          align: 'left'
        },
        {
          label: this.$t('Shipping'),
          name: 'shipping',
          sortable: true,
          align: 'left'
        },
        {
          label: this.$t('Time'),
          name: 'deliveryTime',
          sortable: true,
          align: 'left'
        },
        {
          label: this.$t('Price'),
          name: 'price',
          sortable: true,
          align: 'left'
        }
      ],
      rows: [],
      totalRecords: 0,
      deliveryServices: {},
      deliveryServicesValues: [],
      servicePoints: [],
      center: [43.204666, 27.910543],
      zoom: 10,
      url: 'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      markers: [],
      hotline: null,
      latLngArray: [],
      minValue: 0,
      maxValue: 1,
      senderGeo: null,
      isShowDebug: false,
      marker: null,
      currentPoint: {},
      tab: 'prices',
      userWidth: null,
      userHeight: null,
      userLength: null,
      userWeight: null,
      result: {},
      lastLocalitySearch: '',
      search: '',
      isExpandedStatus: false,
      isWaitOn: false,
    }
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ]),
    filteredServicePoints () {
      const text = this.search.toLowerCase()
      return this.servicePoints.filter(x => JSON.stringify(x).toLowerCase().includes(text))
    },
    results () {
      return Object.values(this.result)
    },
    servicePointsWithGeo () {
      return this.servicePoints.filter(point => {
        return typeof point.geo === 'string' && point.geo.length > 0
      })
    },
    schema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'multiselect',
                label: this.$t('Sender'),
                wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
                field: 'sender',
                value: this.addressModel.sender,
                required: true,
                disabled: this.disabled,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  return this.$service.sender.getAll(query)
                },
                onChange: (sender) => {
                  this.$emit('sender-change', sender)
                  this.addressModel.sender = sender

                  if (this.addressModel.sender && this.addressModel.locality) {
                    this.loadItems()
                  }
                }
              },
              {
                type: 'multiselect',
                label: this.$t('Location'),
                wrapperStyleClasses: 'col-12 col-md-8 q-pa-xs',
                field: 'locality',
                value: this.addressModel.locality,
                disabled: this.disabled || this.isFocusedPostcode || this.postcodesLoading,
                required: true,
                minimumLength: 3,
                customLabel (row) {
                  if (!row || typeof row !== 'object') {
                    return ''
                  }

                  let base = row.type + ' ' + row.name + (row.postcode ? ', ' + row.postcode : '')

                  if (row.embedded && row._embedded.area) {
                    base += ' (' + row._embedded.area.name + ')'
                  }

                  return base
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  return this.$service.locality.getAll(query)
                },
                onChange: (locality) => {
                  this.$emit('locality-change', locality)
                  this.addressModel.locality = locality

                  if (this.addressModel.sender && this.addressModel.locality) {
                    this.loadItems()
                  }
                }
              }
            ]
          }
        ]
      }
    }
  },
  watch: {
    locality: {
      handler () {
        this.loadItems()

        if (this.locality) {
          this.buildMap()
        }
      },
      deep: true
    },
    postcode () {
      this.loadItems()
    },
    estimatedCost () {
      this.loadItems()
    },
    payment () {
      this.loadItems()
    },
    calculation () {
      this.$emit('calculation', this.calculation)
    },
    servicePoint: {
      handler (newValue) {
        const { latitude, longitude } = newValue._embedded.locality.geo
        this.center = [latitude, longitude]
      },
      deep: true
    }
  },
  mounted () {
    this.buildMap()

    this.loadItems()

  },
  unmounted() {
      this.isSaveActive = false
  },
    updated () {
    if (this.locality) {
      this.updateMapSize()
    }
  },
  methods: {
    ...mapActions([
      'loadLocalities',
      'loadServicePoint',
      'loadServicePoints',
      'loadSenders'
    ]),
    ...mapMutations([
      'addErrorNotification'
    ]),
    sortByPriceAsc() {
      // Sort the items by price in ascending order
      const rowItems = this.results.map(row => row.items).flat();
      rowItems.sort((a, b) => a.price - b.price);
      this.results.forEach(row => {
        row.items = [...rowItems];
      });
    },
    customSortMethod(items, sortBy, descending) {
      // Clone the items array to avoid modifying the original data
      const clonedItems = [...items];

      // Custom sorting logic
      clonedItems.sort((a, b) => {
        const aValue = a[sortBy];
        const bValue = b[sortBy];

        if (descending) {
          return aValue > bValue ? -1 : 1;
        } else {
          return aValue > bValue ? 1 : -1;
        }
      });

      return clonedItems;
    },
    handleSave () {
      const dimensions =  {
        weight: this.userWeight || this.weight ,
        dimensions: {
            x: `${this.userWidth || this.width}`,
            y: `${this.userHeight || this.height}`,
            z: `${this.userLength || this.length}`
        }
      }
      this.$emit('update-dimensions', dimensions)
    },
    handleSearch (e) {
      this.search = e
    },
    handleSubmitDimmensions (e) {
      e.preventDefault()
        this.isSaveActive = true
      this.loadItems()
    },
    // Get pin icon by delivery service
    createIcon (point) {
      const service = this.deliveryServices[point.deliveryService.id]
      const pin = this.pinTypes[service.name] || this.pinTypes.default

      return this.isChoosed(this.createGeo(point.geo))
        ? this.pinTypes.selectedPin
        : pin
    },
    handleTabChange (tabName) {
      this.tab = tabName
    },
    findLogo (id) {
      return (this.deliveryServices[id] || {}).logo
    },
    handleMarkerSelect (point) {
      this.currentPoint = point
      const coords = this.createGeo(point.geo)
      this.marker = coords
      this.center = coords
      this.openPopUp(coords)
    },
    openPopUp (latLng) {
      this.$refs.features.leafletObject.openPopup(latLng)
    },
    getMarkerSize (coords) {
      return this.isChoosed(coords)
        ? [34, 50]
        : [26, 40]
    },
    isChoosed (coords) {
      return !!this.marker && coords[0] === this.marker[0] && coords[1] === this.marker[1]
    },
    handleRateClick (row) {
      const types = ['service_point', 'self_service_point']

      if (types.includes(row.type) && this.servicePoints.length > 0) {
        this.center = this.createGeo(this.locality.geo)

        setTimeout(() => {
          this.zoom = 13
        }, 500)
      }

      this.$emit('rate', row , this.isExpanded)
    },
    handleServicePoint (point) {
      this.$emit('on-service-point-change', point)
    },
    createGeo (value = '') {
      const indexOfOpen = value.indexOf('(') + 1
      const indexOfClose = value.indexOf(')')

      return value.slice(indexOfOpen, indexOfClose).split(' ').map(Number).reverse()
    },
    // Get locality by sender address
    getSenderGeo () {
      if (this.senderGeo) {
        return Promise.resolve(this.senderGeo)
      }

      if (!this.senderAddress) {
        return Promise.reject(new Error(this.$t('No sender geo')))
      }

      let senderLocality = this.senderAddress.locality || (this.senderAddress._embedded || {}).locality

      if (!senderLocality) {
        return Promise.reject(new Error(this.$t('Sender address locality not set')))
      }

      return HALApiService.getLink(senderLocality._links.self.href)
        .then(data => {
          if (!data.geo) {
            return Promise.reject(new Error(this.$t('No sender geo')))
          }

          this.senderGeo = this.createGeo(data.geo)

          return this.senderGeo
        })
    },
    setRoute () {
      if (this.latLngArray.length === 2) {
        return Promise.resolve(this.latLngArray)
      }

      return this.getSenderGeo()
        .then(senderGeo => {
          this.latLngArray[0] = [...senderGeo, 0]

          if (this.locality.geo) {
            this.latLngArray[1] = [...this.createGeo(this.locality.geo), 1]
          }

          return this.latLngArray
        })
    },
    fitBounds () {
      return this.setRoute()
        .then(latLngArray => {
          if (latLngArray.length > 0 && this.$refs.calculatorMap.mapObject) {
            this.$refs.calculatorMap.mapObject.fitBounds(window.L.latLngBounds(latLngArray), {
              maxZoom: 13,
              minZoom: 3
            })
          }

          this.updateMapSize()

          return latLngArray
        })
    },
    buildMap () {
      if (!this.locality || !this.locality.geo) {
        this.geolocate()
        return
      }

      this.fitBounds()
        .then(() => {
          if (this.locality.geo) {
            this.center = this.createGeo(this.locality.geo)

            setTimeout(() => {
              this.zoom = 13
            }, 500)
          }
        })
        .catch(err => {
          console.debug(err.message)

          if (this.locality.geo) {
            this.center = this.createGeo(this.locality.geo)

            setTimeout(() => {
              this.zoom = 13
            }, 500)
          } else {
            this.geolocate()
          }
        })
    },
    geolocate () {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = [position.coords.latitude, position.coords.longitude]
      })
    },
    updateMapSize () {
      if (this.$refs.calculatorMap.mapObject) {
        this.$refs.calculatorMap.mapObject.invalidateSize()
      }
    },
    getCurrencyIcon (currency) {
      const currencies = {
        RUB: 'fa-ruble-sign',
        KZT: 'fa-tenge'
      }

      return currencies[currency] || null
    },

    getPostcode () {
      if (this.postcode) {
        return this.postcode
      }

      // if (this.addressModel.locality.postcode) {
      //   return this.addressModel.locality.postcode
      // }

      return null
    },
    checkLogoServices (service) {
      if(service.logo.includes('http')) {
        return service.logo
      } else {
        return this.appOptions.defaultServer + service.logo
      }
    },
    checkLogo (service) {
      if ((this.deliveryServices[service.deliveryService.id] || {}).logo.includes('http')) {
        return (this.deliveryServices[service.deliveryService.id] || {}).logo
      } else {
        return this.appOptions.defaultServer + this.deliveryServices[service.deliveryService.id].logo
      }
    },
    // Get result for current data (sender, locality, price, sizes)
    loadItems () {
      if (!this.deliveryRequest) {
        this.addErrorNotification('No sender or locality!')
        return
      }
      this.isWaitOn = true
      let query = {
        debug: !!this.appOptions.debug,
        clearCache: !!this.appOptions.debug,
        weight: this.userWeight || this.weight || 1,
        width: this.userWidth || this.width || 1,
        height: this.userHeight || this.height || 1,
        length: this.userLength || this.length || 1,
        divisor: this.divisor
      }

      return HALApiService.get(`/delivery-services/calculator/${this.deliveryRequest.id}`, query)
        .then(data => {
          this.calculation = {
            response: data
          }

          this.deliveryServices = data.deliveryServices.reduce((acc, val) => {
            return {
              ...acc,
              [val.id]: val
            }
          }, {})

          this.deliveryServicesValues = data.deliveryServices

          this.servicePoints = data.servicePoints || []

          const titles = {
            courier: 'Courier',
            service_point: 'Pick Up Points',
            post: 'Post',
            simple: 'Others'
          }

          this.result = Object.values(data.rates).reduce((acc, value) => {
            if (!acc[value.type]) {
              acc[value.type] = {
                title: titles[value.type],
                items: []
              }
            }

            acc[value.type].items.push(value)
            return acc
          }, {})
          this.sortByPriceAsc()
        })
        .catch(error => {
          this.addErrorNotification(error)
          console.trace(error)
        })
          .finally(()=> {
            this.isWaitOn = false
            // if(this.isExpanded) {
            //     const dimensions =  {
            //         weight: this.userWeight || this.weight ,
            //         dimensions: {
            //             x: `${this.userWidth || this.width}`,
            //             y: `${this.userHeight || this.height}`,
            //             z: `${this.userLength || this.length}`
            //         }
            //     }
            //
            //     this.$emit('update-dimensions', dimensions)
            // }

          })
    },
    enableCatchAll ( value ) {
      this.$eventBus.update('catchAll', {
        catchAll: value
      })
    },
    handleNumberUpdate (field , value) {
      if(field === 'width') {
        this.userWidth = value
      }
      if(field === 'height') {
        this.userHeight = value
      }
      if(field === 'length') {
        this.userLength = value
      }
      if(field === 'weight') {
        this.userWeight = value
      }
    }
  }
}
</script>
